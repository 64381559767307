@import "src/assets/styles/mixin";

.personal-settings-section {
  position: relative;
  z-index: 99;
  margin-top: 300px;
  @include screen-max-width-1200 {
    margin-top: 220px;
  }
  @include screen-max-width-991 {
    margin-top: 160px;
  }
  @include screen-max-width-767 {
    margin-top: 140px;
  }
}

.personal-settings-list {
  margin-top: 100px;
  width: 100%;
  gap: 25px;

  @include screen-max-width-1200 {
    margin-top: 80px;
  }
  @include screen-max-width-991 {
    margin-top: 60px;
  }
  @include screen-max-width-767 {
    margin-top: 40px;
  }
}

.modal-information {
  padding-bottom: 50px;

  h3 {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 36px;
    line-height: 39.6px;
    margin-bottom: 30px;
    @include screen-max-width-991 {
      font-size: 24px;
    }
  }

  p {
    color: $white_05;
    font-size: 20px;
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    line-height: 28px;
    @include screen-max-width-991 {
      font-size: 18px;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      margin-top: 20px;
    }
  }

  h4 {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 20px;
    line-height: 28px;
    margin-top: 40px;
    margin-bottom: 20px;
    @include screen-max-width-991 {
      font-size: 20px;
    }
  }

  h5 {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 20px;
    line-height: 28px;
    margin-top: 40px;
    @include screen-max-width-991 {
      font-size: 20px;
    }
  }

  iframe {
    width: 100%;
    height: 400px;
  }

  ul {
    li {
      color: $white_05;
      font-size: 20px;
      font-family: "HelveticaNeueCyrMedium", sans-serif;
      font-weight: 550;
      line-height: 28px;
      @include screen-max-width-991 {
        font-size: 18px;
      }
    }
  }
}

.information-card-modal {
  .G-modal-content {
    max-width: 800px;
    width: 90VW;
  }
}


.personal-settings-modal {
  .modal-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 9995;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }

  .personal-settings-modal-content {
    transition: $transition;
    transform: translateX(100%);
    position: fixed;
    background-color: $black;
    padding: 30px;
    padding-right: 10px;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;

    z-index: 9999;
    @include screen-max-width-1200 {
      width: 100vw;
    }
  }

  .personal-settings-modal-body {
    margin-top: 20px;
    max-height: calc(100vh - 80px);
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 2px;
    }


    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $white_05;
    }
  }

  &.open-modal {
    .personal-settings-modal-content {
      transform: translateX(0);
    }

    .modal-bg {
      opacity: 1;
      visibility: visible;
    }
  }
}

.modal-images {
  width: 100%;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }


  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $white_05;
  }

  .modal-image-item {
    max-width: 250px;
    aspect-ratio: 1 / 1;
    width: 100%;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    @include screen-max-width-767 {
      max-width: 100%;
      margin-left: 0;
    }
  }
}

.modal-video {
  width: 100%;
  margin-top: 20px;

  iframe {
    width: 100%;
    height: 500px;
  }

  @include screen-max-width-991 {
    iframe {
      height: 300px;
    }
  }
}

.submodal-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.modal-slide-image {
  padding: 10px;
}

.modal-images {
  .slick-next {
    right: 0;

    &:before {
      font-size: 30px;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      font-size: 30px;
    }

    z-index: 99;
  }
}

.popup-slider-wrapper {
  width: 100%;

  .slick-next {
    right: 20px;

    &:before {
      font-size: 30px;
    }
  }

  .slick-prev {
    left: 20px;

    &:before {
      font-size: 30px;
    }

    z-index: 99;
  }
}

.slider-popup {
  height: 100vh;
}

.personal-settings-modal-header {
  padding-right: 10px;
}
