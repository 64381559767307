@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("./HelveticaNeueCyr Roman.ttf");
  font-weight: 400;

}
@font-face {
  font-family: 'HelveticaNeueCyrBold';
  src: url("./HelveticaNeueCyr Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: 'HelveticaNeueCyrMedium';
  src: url("./HelveticaNeueCyr Medium.ttf");
  font-weight: 500;
}