@import "src/assets/styles/mixin";

.cover-section {
  margin-top: 200px;

  h1 {
    font-size: 90px;
    line-height: 99px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    background: $white_gradient_text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(-20px);
    transition: 1s;
    opacity: 0;
    position: relative;z-index: 2;

    &.show-title {
      opacity: 1;
      transform: translateY(0);
    }

    @include screen-max-width-1440 {
      font-size: 70px;
      line-height: 72px;
    }
    @include screen-max-width-1200 {
      font-size: 60px;
    }
    @include screen-max-width-767 {
      font-size: 40px;
      line-height: 56px;
    }
    @include screen-max-width-400 {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .cover-bg {
    position: absolute;
    right: 0;
    width: 1400px;
    height: 1400px;
    top: 0;

    &.fix-cover-image {
      top: -101px;
      @include screen-max-width-767 {
        top: -80px;

      }
    ;
    }

    @include screen-max-width-991 {
      width: 1000px;
      height: 800px;
    }
  }

  @include screen-max-width-991 {
    margin-top: 141px;
  }
  @include screen-max-width-400 {
    margin-top: 100px;
  }
}

.fixed-section {
  margin-top: 101px;
  @include screen-max-width-767 {
    margin-top: 80px;
  }
}

.video-block {
  video {
    width: 100%;

    @include screen-max-width-576 {
      width: 700px;
    }
  }

  margin-top: 20px;

  width: 100%;
  height: auto;
}

.about-engine {
  margin-top: 180px;
  @include screen-max-width-1200 {
    margin-top: 120px;
  }
  span {
    font-family: HelveticaNeueCyrMedium, sans-serif;
    font-weight: 550;
    background: linear-gradient(97.27deg, #676767 -2.65%, #FFFFFF 80.16%) 0 0/200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: slice;
    font-size: 110px;
    line-height: 121px;
    display: initial;
    width: 100%;
    max-width: 100%;

    &.show-title {
      opacity: 1;
      transform: translateY(0);
    }

    @include screen-max-width-1440 {
      font-size: 80px;
      line-height: 88px;
    }

    @include screen-max-width-991 {
      font-size: 70px;
      line-height: 77px;
    }
    @include screen-max-width-767 {
      font-size: 36px;
      line-height: 43.2px;
      margin-top: 100px;

    }
  }
}

.cover-wrapper {
  overflow: hidden;
}