@import "src/assets/styles/mixin";

.about-section {
  width: 100%;
  margin-top: 200px;
  position: relative;

  .about-us-tittle {
    max-width: 800px;
  }

  @include screen-max-width-1200 {
    margin-top: 140px;
  }
  @include screen-max-width-991 {
    margin-top: 100px;
  }
  @include screen-max-width-767 {
    margin-top: 0;
  }
}

.info-content {
  margin-top: 120px;
  display: flex;
  justify-content: space-between;

  transform: translateY(50px);
  transition: 0.8s;
  opacity: 0;
  position: relative;
  z-index: 2;

  &.show-info {
    opacity: 1;
    transform: translateY(0);
  }

  @include screen-max-width-991 {
    flex-direction: column;
    gap: 50px;
    margin-top: 80px;
  }
;
}

.info-Box {
  width: 31%;
  display: flex;
  flex-direction: column;
  color: $white;
  gap: 30px;
  @include screen-max-width-991 {
    width: 100%;
  }

  img {
    width: 43px;
  }

  h3 {
    font-family: 'HelveticaNeueCyrMedium', sans-serif;
    font-weight: 550;
    font-size: 36px;
    line-height: 39.6px;

    @include screen-max-width-767 {
      font-size: 24px;
      line-height: 26.4px;
    }
  }

  p {
    font-size: 24px;
    line-height: 33.6px;
    font-family: 'HelveticaNeueCyrMedium', sans-serif;
    font-weight: 550;
    color: $white_05;
    @include screen-max-width-1440 {
      font-size: 20px;
      line-height: 28px;
    }
    @include screen-max-width-1200 {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
}

.about-us-bg {
  position: absolute;
  right: 0;
  width: 1200px;
  height: 1200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: -300px;

  @include screen-max-width-991 {
    width: 1000px;
    height: 1000px;
    top: -200px;
  }
}
