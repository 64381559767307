@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.our-partners {
  margin: 200px 0;
  margin-bottom: 120px;
  overflow: hidden;

  transform: translateY(50px);
  transition: 0.8s;
  opacity: 0;

  &.show-section {
    opacity: 1;
    transform: translateY(0);
  }

  @include screen-max-width-1200 {
    margin: 80px 0;
  }
  @include screen-max-width-991 {
    margin: 20px 0;
    margin-top: 100px;
  }

  .our-partner-tittle {
    margin-bottom: 65px;
    @include screen-max-width-991 {
      margin-bottom: 20px;
    }

    h3 {
      font-family: "HelveticaNeueCyrMedium", sans-serif;
      font-weight: 550;
      font-size: 48px;
      line-height: 63.98px;
      background: linear-gradient(105.05deg, #FFFFFF 4%, #666666 60.64%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include screen-max-width-991 {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }

  .partner-slider-item {
    width: 100%;
    margin: 0 55px;
    margin-left: 0;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include screen-max-width-767 {
      margin: 0;
    }
  }

  img {
    opacity: 0.7;
    width: auto;
    max-width: unset;
    object-fit: contain;
    @include screen-max-width-991 {
      height: 60px;
    }
    @include screen-max-width-767{
      height: 40px;
    }
  }

  .swiper-slide {
    width: fit-content;
  }
}

.partners-slider {
  width: 100%;
  height: 120px;

  .splide__slide {
    width: fit-content !important;
  }
}