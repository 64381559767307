@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.usearea-section {
  margin-top: 200px;
  margin-bottom: 140px;
  position: relative;
  z-index: 9;

  transform: translateY(50px);
  transition: 0.8s;
  opacity: 0;

  &.show-area{
    opacity: 1;
    transform: translateY(0);
  }
  @include screen-max-width-1200 {
    margin-top: 100px;
    margin-bottom: 120px;
  }
  @include screen-max-width-991 {
    margin-bottom: 100px;
  }
}

.usearea-title {
  margin-bottom: 100px;
  max-width: 1200px;
  @include screen-max-width-991 {
    margin-bottom: 60px;
  }
}

.slider-wrapper {
  overflow: hidden;
  width: 100%;
  @include screen-max-width-991 {
    flex-direction: column;
  }
}

.slider-header {
  width: 40%;
  @include screen-max-width-991 {
    width: 100%;
    height: 90px;
    overflow: hidden;
  }

  .slick-slider {
    margin-top: -28px;
    @include screen-max-width-991 {
      margin-top: 0;
    }
  }
  .slick-slide {
    display: inline-block;
    height: auto; /* Ensure the height is automatic to handle different item heights */
  }

   .slick-list {
    overflow: hidden;
  }
  @include screen-max-width-400{
      width: 600px;

  }

}

.main-slider {
  width: 60%;
  @include screen-max-width-991 {
    width: 100%;
    margin-top: 10px;
  }
}

.slider-header-item {
  @include screen-max-width-991 {
    padding: 2px;
  }

  .usearea-icon-content {
    position: relative;
    margin-right: 40px;
    @include screen-max-width-767 {
      margin-right: 20px;
    }
  }

  .usearea-icon {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid $white_02;
    background-color: $black;
    z-index: 55;
    img{
      filter: invert(1) grayscale(1);
    }
    @include screen-max-width-767 {
      width: 65px;
      height: 65px;
    }
  }

  &.selected-usearea, &.active {

    .circle-select {
      border-radius: 100%;
      content: '';
      background-image: linear-gradient(180deg, #660C00 0%, #874900 100%);
      top: -2px;
      left: -2px;
      bottom: -2px;
      right: -2px;
      position: absolute;
    }

    .usearea-icon {
      position: relative;
      border: 1px solid transparent;
    }
  }

  p {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;

    @include screen-max-width-1440 {
      font-size: 24px;
      line-height: 26.4px;
    }
    @include screen-max-width-1200 {
      white-space: nowrap;
    }
    @include screen-max-width-767 {
      font-size: 20px;
      line-height: 22px;
    }
  }


  .slider-item-mobile {
    margin-right: 40px;
    margin-top: 28px;
    cursor: pointer;
    @include screen-max-width-991 {
      margin-top: 0;
      margin-right: 30px;
    }
  }
}

.slick-current {
  .circle-select {
    border-radius: 100%;
    content: '';
    background-image: linear-gradient(180deg, #660C00 0%, #874900 100%);
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    position: absolute;
  }

  .usearea-icon {
    position: relative;
    border: 1px solid transparent;
  }
}

.usearea-selected {
  min-height: 620px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  &:before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  }

  p{
    position: relative;
    z-index: 9;
    max-width: 700px;
    width: 100%;
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
    @include screen-max-width-1200{
      font-size: 20px;
      line-height: 28px;
    }

  }
  @include screen-max-width-1200 {
    min-height: 600px;
  }
  @include screen-max-width-767 {
    min-height: 480px;
  }
}

.slider-desktop-wrapper{
  width: 100%;
  gap: 20px;
  @include screen-max-width-991{
    display: none;
  }

  .slider-navigation{
    width: 40%;
  }
  .slider-selected-image{
    width: 60%;
    .slider-image-content{
      padding: 40px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }

      p{
        max-width: 700px;
        width: 100%;
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 28px;
        font-weight: 400;
        line-height: 33.6px;
        position: relative;
        z-index: 9;
        @include screen-max-width-1200{
          font-size: 20px;
          line-height: 28px;
        }

      }

    }
  }
}

.slider-mobile-wrapper{
  display: none;
  @include screen-max-width-991{
    display: block;
  }
}
