@import "src/assets/styles/variables";
@keyframes pulse {
  from {
    opacity: 1
  }
  to {
    opacity: 0.5
  }
}

.G-skeleton {
  display: flex;
  background-color: $white_05;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  animation-name: pulse;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  &.circle {
    border-radius: 100%;
  }
}
