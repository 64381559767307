@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.workshop-section {
  margin-top: 140px;
  overflow: hidden;
  position: relative;
  padding-top: 100px;

  @include screen-max-width-767{
    padding-top: 0;
  }

  transform: translateY(50px);
  transition: 0.8s;
  opacity: 0;

  &.show-section{
    opacity: 1;
    transform: translateY(0);
  }
}
.workshop-section-wrapper{
  width: 100%;
  padding-bottom: 60px;
  @include screen-max-width-1440{
    flex-direction: column-reverse;
  }
}

.workshop-section-users {
  width: 40%;
  position: absolute;
  left: 0;
  @include screen-max-width-1440{
    width: 100%;
    position: relative;
    margin-top: 80px;
  }
  .workshop-slider-item{
    width: 100%;
    background-size: cover;
    background-position: center;

  }
  @include screen-max-width-767{
    margin-top: 60px;
  }

  .slick-dots{
    bottom: -40px;
    li{
      margin: 0 2px;
    }
    button{
      width: 14px;
      height: 14px;
      background-color: $white_02;
      border-radius: 50%;

      &:before{
        content: '';
      }
    }
    .slick-active{
      button{
        background-color: $white_06;
      }
    }
  }
}

.workshop-section-info {

  width: 55%;
  margin-left: 100px;

  .workers-image {
    color: $white_03;
    margin-bottom: 100px;
    @include screen-max-width-1200{
      margin-bottom: 40px;
    }
  }
  @include screen-max-width-1440{
    width: 100%
  }

  h3 {
    font-family: 'HelveticaNeueCyrMedium', sans-serif;
    font-weight: 550;
    font-size: 80px;
    line-height: 88px;
    background: linear-gradient(105.05deg, #FFFFFF 4%, #666666 60.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include screen-max-width-1580{
      font-size: 60px;
      line-height: 70px;

    }
    @include screen-max-width-1200{
      font-size: 40px;
      line-height: 44px;
    }
  }

  p {
    font-family: 'HelveticaNeueCyrMedium', sans-serif;
    font-weight: 550;
    font-size: 24px;
    line-height: 33.6px;
    text-align: left;
    margin-top: 40px;
    color: $white_05;
    @include screen-max-width-1200{
      font-size: 18px;
      line-height: 25.2px;
    }
  }

}


.workshop-images{
  width: 100%;
  height: 700px;

  @include screen-max-width-767{
    height: 400px;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

