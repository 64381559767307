@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.engine-card {
  width: calc(50% - 12.5px);
  background: $gray_gradient;
  padding: 60px;
  min-height: 610px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  transform: translateY(50px);
  transition: 0.7s;
  opacity: 0;

  &.show-card{
    opacity: 1;
    transform: translateY(0);
  }

  @include screen-max-width-1200 {
    min-height: 550px;
  }
  @include screen-max-width-1440 {
    padding: 40px;
  }

  @include screen-max-width-991 {
    min-height: 450px;
    width: 100%;
    padding: 40px 20px;
  }
  @include screen-max-width-576{
    min-height: 430px;
  }


  .engine-image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }


  .engine-info {

    h3 {
      font-size: 40px;
      font-weight: 400;
      line-height: 44px;
      padding-right: 70px;
      @include screen-max-width-767 {
        font-size: 32px;
        line-height: 35.2px;
      }
      @include screen-max-width-576{
        font-size: 26px;

      }
    }

    p {
      font-size: 20px;
      line-height: 27px;
      font-family: 'HelveticaNeueCyrMedium', sans-serif;
      font-weight: 550;
      max-width: 540px;
      color: $white_05;
      @include screen-max-width-1200 {
        font-size: 18px;
        line-height: 25.2px;
      }
      @include screen-max-width-767 {
        max-width: 440px;

      }
    }
  }
  .engine-card-1{

    .engine-image{
      width: 420px;
      height: 409px;
      right: -20px;
      @include screen-max-width-1580{
        width: 400px;
        height: 400px;
      }
      @include screen-max-width-1200{
        width: 290px;
        height: 272px;
        bottom: 00px;
        right: 0;
      }
      @include screen-max-width-576{
        width: 190px;
        height: 172px;
        bottom: 0;

      }
    }
  }
  .engine-card-2{
    .engine-image{
      width: 457px;
      height: 417px;
      bottom: 10px;
      @include screen-max-width-1580{
        width: 400px;
        height: 400px;
      }
      @include screen-max-width-1200{
        width: 290px;
        height: 340px;
      }
      @include screen-max-width-576{
        width: 190px;
        height: 208px;
        bottom: -10px;
      }
    }
  }
  .engine-card-3{
    .engine-image{
      width: 536px;
      height: 558px;
      bottom: -40px;
      @include screen-max-width-1580{
        width: 450px;
        height: 450px;
        bottom: -20px;
      }
      @include screen-max-width-1200{
        width: 357px;
        height: 393px;
        bottom: 0;
        right: -20px;
      }
      @include screen-max-width-576{
        width: 277px;
        height: 293px;
        bottom: -30px;

      }
    }
  }
  .engine-card-4{
    p{
      max-width: 334px;
    }
    .engine-image{
      width: 466px;
      height: 364.5px;
      bottom: -10px;
      @include screen-max-width-1580{
        width: 350px;
        height: 327px;
        bottom: -30px;
      }
      @include screen-max-width-1200{
        width: 270px;
        height: 300px;
      }
      @include screen-max-width-576{
        width: 257px;
        height: 293px;
        bottom: -30px;
      }

    }
  }
  .engine-card-5{
    p{
      max-width: 334px;
    }
    .engine-image{
      width: 550px;
      height: 454.5px;
      @include screen-max-width-1580{
        width: 450px;
        height: 387px;
      }
      @include screen-max-width-1200{
        width: 280px;
        height: 300px;
      }
      @include screen-max-width-576{
        width: 270px;
        height: 243px;
        bottom: -30px;
      }

    }
  }
  .engine-card-6{
    p{
      max-width: 334px;
    }
    .engine-image{
      width: 400px;
      height: 424.5px;
      bottom: -10px;
      right: -40px;
      @include screen-max-width-1200{
        width: 280px;
        height: 300px;
      }
      @include screen-max-width-576{
        width: 300px;
        height: 203px;
        bottom: -30px;
      }

    }
  }
  .more-info {
    color: $white_05;
    cursor: pointer;

    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 33.6px;
      color: $white_05;
    }

    @include screen-max-width-767 {
      display: none !important;
    }
  }
}

.mobile-show-more {
  display: none !important;
  position: absolute;
  top: 30px;
  right: 30px;

  color: $white;

  @include screen-max-width-767 {
    display: block !important;
  }
  @include screen-max-width-576{
    display: none !important;
  }
}
