@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.G-empty-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  transition: $transition;
  width: 100vw;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  overflow: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-bg{
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 99;
  }

  .empty-modal-close{
    position: absolute;
    right: 20px;
    top: 20px;
    color: white;
    z-index: 9999;
  }

  .G-modal-content{
    width: 100vw;
    height: 100%;
    z-index: 9999;
    transition: $transition;
    transform: translateY(-100px);
    background-color: $black;
    padding: 20px;
    position: relative;
  }
}


.css-transition-enter {
  opacity: 0;
  .G-modal-content{
    transform: translateY(-100px);
  }

}
.css-transition-enter-active {
  opacity: 1;
  .G-modal-content{
    transform: translateY(0);
  }


}
.css-transition-enter-done {
  opacity: 1;
  .G-modal-content{
    transform: translateY(0);
  }

}
.css-transition-exit {
  opacity: 1;
  .G-modal-content{
    transform: translateY(0);
  }

}
.css-transition-exit-active {
  opacity: 0;
  .G-modal-content{
    transform: translateY(-100px);
  }


}
.css-transition-exit-done {
  opacity: 0;
  .G-modal-content{
    transform: translateY(-100px);
  }

}

