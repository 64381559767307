@import "./variables.scss";

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
iframe,
ol,
ul,
li,
form,
label,
i,
footer,
header,
menu,
nav,
a,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
video,
canvas,
section,
  /*commonly used tags*/
applet,
object,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
ins,
kbd,
hgroup,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
dl,
dt,
dd,
fieldset,
legend,
article,
aside,
details,
embed,
figure,
figcaption,
output,
section,
summary,
time,
mark,
audio,
center,
ruby {
  margin         : 0;
  padding        : 0;
  border         : 0;
  vertical-align : baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label {
  display : block;
}

ol,
ul {
  list-style : none;
  padding    : 0;
  margin     : 0;
}

table {
  width           : 100%;
  border-collapse : collapse;
  border-spacing  : 0;
}

* {
  outline                     : none;
  box-sizing                  : border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none;
}

a {
  display         : inline-block;
  text-decoration : none;
  font            : inherit;
}

button {
  cursor   : pointer;
  position : relative;
}

input,
button,
select,
textarea {
  display : inline-block;
  padding : 0;
  border  : 0;
  font    : inherit;
}


img {
  max-width  : 100%;
  max-height : 100%;
}

html,
body {
  margin           : 0;
  padding          : 0;
  position         : relative;
  background-color: $black;
  font-family: "HelveticaNeueCyr", sans-serif;
}

p,h1,h2,h3,h4,h5,h6,a,span{
  color: $white;
}