@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.main-tittle{
    font-size: 90px;
    line-height: 99px;
    background: $white_gradient_title;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'HelveticaNeueCyrMedium',sans-serif;
    font-weight: 550;
    @include screen-max-width-1440{
        font-size: 80px;
        line-height: 88px;
    }
    @include screen-max-width-1200{
        font-size: 70px;
        line-height: 77px;
    }
    @include screen-max-width-991{
        font-size: 60px;
        line-height: 66px;
    }
    @include screen-max-width-767{
        font-size: 40px;
        line-height: 44px;
    }
}