@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";


.logo-content {
  z-index: 9999;

  p {
    width: 170px;
    min-width: 170px;
  }
  .G-skeleton{
    min-width: 42px;
  }

  @include screen-max-width-991 {
    img {
      width: 32px;
      height: 35px;
      object-fit: contain;
      z-index: 999;
      position: relative;
    }
  }
  @include screen-max-width-576 {
    p {
      display: none;
    }
  }
}

.menu-content {
  position: relative;
  z-index: 99;
  @include screen-max-width-991 {
    position: fixed;
    width: 100%;
    height: 100vh;
    transition: $transition;
    background-color: $black;
    top: -100%;
    opacity: 0;
    visibility: hidden;
    left: 0;
    padding-top: 80px;
    z-index: 999;
    overflow: hidden;


    .header-mobile-scroll {
      border-top: 1px solid $white_01;
      height: calc(100vh - 80px);
      overflow-y: auto;
      padding: 10px 0;
      padding-bottom: 30px;
      width: 100%;
      z-index: 9;

      &::-webkit-scrollbar {
        display: none;
      }


    }

    &.Chrome {
      .header-mobile-scroll {
        height: calc(100vh - 100px)
      }
    }
    &.Safari {
      .header-mobile-scroll {
        height: calc(100vh - 180px)
      }
    }
    &.ChromeIos {
      .header-mobile-scroll {
        height: calc(100vh - 180px)
      }
    }

    ul {
      width: 100%;
      padding: 60px 35px 50px 35px;
      @include screen-max-width-767{
        padding: 30px 35px 50px 35px;

      }

    }
  }

  .header-links {
    display: none;
    padding: 0 35px;
    width: 100%;

    .header-links-content {
      border-top: 1px solid $white_01;
      display: flex;
      gap: 50px;
      flex-direction: column;
      width: 100%;
      padding-top: 50px;
      @include screen-max-width-991 {
        gap: 25px;
      }
    }

    @include screen-max-width-991 {
      display: flex;


      a {
        font-family: "HelveticaNeueCyrMedium", sans-serif;
        font-weight: 550;
        font-size: 30px;
        line-height: 27px;

        @include screen-max-width-576 {
          font-size: 24px;
          line-height: 33.6px;
        }
      }
    }
  }

  &.show-menu {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
}

.menu-list {
  gap: 50px;

  li {
    cursor: pointer;
    transition: $transition;
    color: $white;
    white-space: nowrap;
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 18px;
    line-height: 17.78px;

    @include screen-max-width-991 {
      font-size: 24px;
      line-height: 23.71px;
    }
  }

  @include screen-max-width-991 {
    gap: 20px;
  }
  @include screen-max-width-991 {
    flex-direction: column;
    gap: 40px;
  }

}

header {
  padding: 27px 0;
  border-bottom: 1px solid $white_01;
  z-index: 999;
  background-color: $black;
  transition: $transition;
  @include screen-max-width-991 {
    padding: 22px 0;
  }

  &.fixed-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    animation-name: animationMenu;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}


@keyframes animationMenu {
  from {
    top: -120px;
  }
  to {
    top: 0;
  }
}

.toggle-menu {
  display: none !important;
  width: 30px;
  height: 14px;
  flex-direction: column;
  justify-content: space-between;
  @include screen-max-width-991 {
    display: flex !important;
  }
  position: relative;
  z-index: 999;

  span {
    width: 100%;
    height: 2px;
    background-color: $white;
    display: block;
    transition: 0.3s;

  }

  &.open-burger {
    justify-content: center;
    align-items: center;

    span {
      &:first-child {
        transform: rotate(45deg) translateX(2px);
      }
    ;

      &:last-child {
        transform: rotate(-45deg) translateX(1px);
      }
    ;
    }
  }
}


.mobile-menu-bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 1200px;
  height: 1200px;
  display: none;
  @include screen-max-width-991 {
    display: block;
  }
}
