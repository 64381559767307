@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";

.our-team-section {
  padding: 130px 0;
  position: relative;
  min-height: 800px;
  overflow: hidden;

  transform: translateY(50px);
  transition: 0.8s;
  opacity: 0;

  &.show-section{
    opacity: 1;
    transform: translateY(0);
  }

  @include screen-max-width-1200{
    padding: 100px 0;
  }
  @include screen-max-width-991{
    padding: 80px 0;
  }
  @include screen-max-width-767{
    padding: 70px 0;
  }

  .arrow-left, .arrow-right {
    &:before {
      display: none;
    }

    width: 56px;
    height: 56px;
    border-radius: 100%;
    border: 1px solid $white_05;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: $white;

  }

  .slider-counter {
    margin-bottom: 25px;
    @include screen-max-width-991 {
      display: none !important;
    }

    h3 {
      font-family: "HelveticaNeueCyrMedium", sans-serif;
      font-weight: 550;
      font-size: 48px;
      line-height: 47.42px;
      color: $white;

      @include screen-max-width-767{
        font-size: 36px;
        font-weight: 550;
        line-height: 48px;
      }
    }

    p {
      margin-left: 4px;
      font-family: "HelveticaNeueCyrMedium", sans-serif;
      font-weight: 550;
      font-size: 16px;
      line-height: 15.81px;
      color: $white_05;
      @include screen-max-width-767{
        font-size: 18px;
        line-height: 25.2px;
      }
    }
  }
}

.our-team-slider {
  max-width: 1280px;
  width: 100%;
  position: absolute;
  right: -50px;

  @media(max-width: 1700px) {
    width: 70vw;
  }
  @include screen-max-width-1440 {
    right: -90px;
  }
  @include screen-max-width-1200 {
    right: -290px;
  }
  @include screen-max-width-991 {
    position: relative;
    right: 0;
    max-width: unset;
    width: 991px;
  }

  @include screen-max-width-767 {
    width: 767px;
  }
  @include screen-max-width-576{
    width: 630px;
  }
}

.team-member-item {

  h3,p{
    @include screen-max-width-576{
      max-width: 230px;
    }
  }
  img {
    width: 400px;
    height: 500px;object-fit: cover;
    @include screen-max-width-767 {
      width: 288px;
      height: 360px;
    }
  }
}

.our-team-title {
  h3 {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 48px;
    line-height: 63.98px;
    background: linear-gradient(105.05deg, #FFFFFF 4%, #666666 60.64%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include screen-max-width-991{
      font-size: 40px;
      line-height: 50px;
    }
  }

  p {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 24px;
    line-height: 33.6px;
    background: $white_05;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include screen-max-width-991{
      font-size: 18px;
      line-height: 25.2px;
    }
  }
}

.slider-options {
  margin-top: 240px;
  @include screen-max-width-991 {
    margin-top: 0;
  }
}

.team-member-item {
  h3 {
    font-family: "HelveticaNeueCyrMedium", sans-serif;
    font-weight: 550;
    font-size: 28px;
    line-height: 30.8px;
    color: $white;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  p {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    background: $white_05;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.our-team-content {
  flex-direction: column !important;
}

.our-team-info {
  @include screen-max-width-991 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

