@import "src/assets/styles/variables";
@import "src/assets/styles/mixin";
.G-container {
  max-width: 1720px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.G-hidden {
  overflow: hidden;
  padding-right: 5px;
  @include screen-max-width-767{
    padding-right: 0;
  }
}

.G-pointer {
  cursor: pointer;
}


::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white_05;
}